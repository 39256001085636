@font-face {
    font-family: "Roboto-Light";
    src: local("Roboto-Light"),
        url("../../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: light;
}


.bg {
    background-color: #13763d;
    -moz-box-shadow: inset -10px 10px 72px #0000006b;
    -webkit-box-shadow: inset -10px 10px 72px #0000006b;
    box-shadow: inset -10px 10px 72px #0000006b;
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.loginWraper {
    /* background-color:  rgba(255, 255, 255, 0.877); */
    color: white !important;
    background-color: #fff;
    box-shadow: 0em 0em 24px 1px #0404044a !important;
    padding: 40px 40px 10px !important;
    border-radius: 20px;
}

.loginWrapperWidth{
    width: 34em;
}

.authInput label.ant-form-item-required {
    color: white !important;
}

.title {
    color: black !important;
    font-size: 30px !important;
    font-weight: normal !important;
    
    font-family: "Roboto-Light";
}

.img-logo {
    max-width: 230px;
}

.bg-secondary {
    background-color: transparent !important;
    /* box-shadow: 0em 0em 11em 5em #ffffff0f; */
}

.white-text {
    color: #ffffff !important;
}

.authButton button.ant-btn-primary {
    /* background-color: #ffffff !important;
    color: #13763d !important; */
    height: 3em !important;
    border-radius: 10px;
}


.authInput input {
    border-radius: 10px;
    border: 1px solid #13763d;
    height: 3em;
}

.emailInput {
    height: 3.6em !important;
    border-radius: 10px;
}

.passInput {
    border-radius: 10px;
}