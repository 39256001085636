.gold, .gold .ant-badge-status-text{
    color: #e8b339;
    background: #2b2111 !important;
    border-color: #594214;
}

.magenta, .magenta .ant-badge-status-text{
    color: #e0529c;
    background: #291321 !important;
    border-color: #551c3b;
}

.volcano, .volcano .ant-badge-status-text{
    color: #e87040;
    background: #2b1611 !important;
    border-color: #592716;
}

.green, .green .ant-badge-status-text{
    color: #52c41a;
    background: #1a2b11 !important;
    border-color: #3b5a14;
}

.cyan, .cyan .ant-badge-status-text{
    color: #33bcb7;
    background: #112123 !important;
    border-color: #144848;
}


.danger, .danger .ant-badge-status-text{
    color: #f5222d;
    background: #2b1111 !important;
    border-color: #591414;
}