@import '~antd/dist/antd.css';

.site-layout .site-layout-background {
  background: #fff;
}

.logo {
  width: 100px;
  height: 41px;
  background-image: url("/public/logo.jpg");
  margin: 10px auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.logo-xs {
  width: 50px;
  height: 50px;
  background: #191919;
  margin: 0 auto;
}

.trigger {
  /* margin-left: 10px;  */
}

.title-xs {
  font-size: 12px;
  margin-top: 9px !important;
  width: 118px;
}

.anticon {
  vertical-align: 0;
}

.floatingAction {
  height: 55px;
  width: 55px !important;
  font-size: 22px;
}

/* 
custom template
*/
.ant-descriptions-item-label {
  /* background-color: #c00521 !important; */
  background-color: #13763d !important;
  color: #fff;
}

.ant-btn-primary {
  background-color: #13763d !important;
  border-color: #13763d !important;
}

.ant-btn-primary:hover {
  color: #fff !important;
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff !important;
  border-color: #c00520 !important;
  background-color: #c00520 !important;
}

.ant-btn-primary[disabled]:hover {
  color: #636363 !important;
}

.ant-btn-primary[disabled] {
  color: #636363;
  border-color: #13763d !important;
  background-color: #13763d !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #c00520;
  border-color: #c00520;
}

a {
  color: #191919 !important;
}

.ant-pagination-item-active {
  border-color: #13763d !important;
}

.sider {
  background-color: #fff;
}

.ant-menu-item-selected {
  color: #fff !important;
  background-color: #13763d !important;
  border-right: 1px solid #c00520 !important;
}

.ant-menu-item-selected a {
  color: #fff !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  color: #fff;
  border-right: 3px solid #13763d !important;
}

.menuOption:hover {
  color: #c00520 !important;
}

.linkOption {
  color: #fff !important;

}

.ant-table-thead>tr>th {
  color: #fff !important;
  background-color: #13763d !important;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  /* Semitransparent */
  background: #80b2944f;
}



.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-picker:hover,
.ant-input-affix-wrapper:hover,
.ant-input:hover,
.ant-input:focus,
.ant-upload.ant-upload-select-picture-card:hover,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #68b187 !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #d8e7de;
  box-shadow: 0 0 0 2px rgb(220 251 232);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #13763d;
  border-color: #13763d;
}

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #68b187;
}


.ant-select-multiple .ant-select-selection-item {
  background-color: #fff;
  padding-left: 0;
  margin-left: 5px;
  border-radius: 0px 17px 17px 0px;
}

.anticon-close svg {
  vertical-align: 0 !important;
}


.loading-wrapper {
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0;
  position: fixed;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000d1;
  z-index: 9999;
}

.loading-content {
  height: 100vh;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #13763d transparent;
}

.loader-xs {
  width: 8px;
  height: 8px;
  border: 1px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 0 10px 0 3px;
}
.loader-xs::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #c8ccca transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 