.ant-upload {
    width: 100% !important;
    margin-top: 1em;
}

.uploadedImage {
    height: 100px;
    margin-top: 0.5em;
    width: auto;
}

.uploader {
    /* background-color: red; */
}

.notConnection {
    background-color: #cbcbcb;
    padding: 1em;
}


@-o-keyframes fadeIt {
    0% {
        background-color: #FFFFFF;
        padding: 0em;
    }

    100% {
        background-color: #cbcbcb;
        padding: 1em;
    }
}

@keyframes fadeIt {
    0% {
        background-color: #FFFFFF;
        padding: 0em;
    }

    100% {
        background-color: #cbcbcb;
        padding: 0.8em;
    }
}

.notConnection {
    background-image: none !important;
    -o-animation: fadeIt 0.5s ease-in-out;
    animation: fadeIt 0.5s ease-in-out;
}