.card-wraper {
    border: 1px solid #13763d;
    border-radius: 5px;
    padding: 10px 0 15px 3px;
    background-color: #13763d;
    color: white;
}

.title {
    font-weight: bold;
}

.info {
    margin-left: 1em;
}

.reloadButton{
    background-color: #05361a !important;
    margin: 0.5em 0.5em 0 0.5em;
}

.removeButton{
    background-color: #c00520 !important;
    margin: 0.5em;
}